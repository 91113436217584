import * as React from "react";
import Head from "next/head";
import CompactFooter from "../components/Footer";
import Nav from "../components/Nav";
import Providers from "../components/Providers";
import { Heading } from "../components/Shared";
import SelectAddress from "../components/SelectAddress";

const Home: React.FC<{}> = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <Head>
        <title>Compare Broadband Deals | Go Fibrely</title>
        <meta
          name="description"
          content="Let's switch your broadband. Limited time offers available in your area from GoFibrely. Enter your postcode now!"
        />
        <meta
          name="Keywords"
          content="Broadband Deals, Cheap Internet Offers, Find Broadband, Broadband Deals Near Me, WiFi Packages"
        />
      </Head>
      <Nav logoLink="/" theme="dark" />
      <div className="flex-grow-1 align-items-center bg-blue">
        <div className="container">
          <Providers title="Switch to find great Broadband Deals in Your Area" />
          <section className="container-sm-fixed text-center pt-5 pb-5 mx-auto">
            <div>
              <Heading
                title="What is your postcode?"
                isBold={true}
                variant={3}
              />
              <p className="mt-4">
                Enter your postcode below and click ‘Find Address’. Then choose
                your address from the selection that appears.
              </p>
            </div>
            <SelectAddress />
          </section>
          <section className="container-sm-fixed p-5 mx-auto bg-white shadow-sm border mb-5">
            <Heading title="Broadband Deals" variant={5} isBold={true} />
            <p className="mt-3">
              Don&apos;t let your broadband hold you back. Switch to a better
              broadband deal today. Choose from the big providers such as
              TalkTalk, Virgin, Sky, BT, Shell and more. Enter your postcode to
              find the best speeds and deals available in your area.
            </p>
          </section>
        </div>
      </div>
      <CompactFooter />
    </div>
  );
};

export default Home;
